import React, { useState } from "react";
import axios from "axios";
import J from "../../styled-components/aboutJoin"
import { useHistory } from 'react-router-dom';

function JoinNew() {
  // 아이디(이메일)
  const [emailCheck, setEmailCheck] = useState(""); // 사용자 입력 이메일 상태
  const [checkResult, setCheckResult] = useState(""); // 중복 체크 결과 상태
  const [isChecked, setIsChecked] = useState(false); // 중복 체크 여부 상태
  // 비즈니스 이메일
  const [businessEmailCheck, setBusinessEmailCheck] = useState(""); // 사용자 입력 비즈니스 이메일 상태
  const [businessEmailWarning, setBusinessEmailWarning] = useState("");
  // 뉴스레터, 이벤트, 혜택 정보 수신 여부 상태
  const [isSendEmailChecked, setIsSendEmailChecked] = useState(false);
  // 이름
  const [name, setName] = useState(""); // 사용자 입력 이름 상태
  const [nameWarning, setNameWarning] = useState("");
  // 소속 기관
  const [organizationType, setOrganizationType] = useState(""); // 기관 성격 선택 상태
  // 팝업에서 전달 받은 소속 기관 고유 번호
  const [orgSeq, setOrgSeq] = useState(null);
  // 팝업에서 전달 받은 소속 기관명
  const [orgName, setOrgName] = useState("");
  const [orgWarning, setOrgWarning] = useState("");
  // 이전 페이지로 이동
  const history = useHistory();

  // 이메일 정규식
  const emailRegex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");

  // 이메일 입력 상태 변경 함수
  const handleInputChange = (e) => {
    setEmailCheck(e.target.value);
    if (isChecked) {
      setIsChecked(false); // 이메일이 입력되면 중복체크 버튼을 다시 보여주기 위해 isChecked를 false로 변경
    }
  };

  // 아이디 중복 체크 함수
  const handleDuplicateCheck = async () => {
    if (!emailCheck) {
      setCheckResult("아이디(이메일)를 입력해주세요.");
    }
    else if (!emailRegex.test(emailCheck)) {
      setCheckResult("아이디(이메일) 형식이 올바르지 않습니다.");
    }
    else {
      try {
        const response = await axios.get('/api/admin/checkEmail?email=' + emailCheck);

        const message = response.data.data.message;

        if (message === "사용가능한 이메일 입니다.") {
          setCheckResult("");
        }
        else {
          setCheckResult(response.data.data.message);
        }

        // 중복 체크 결과가 0이면 중복된 이메일이 없다는 뜻
        if (response.data.data.code === 0) {
          setIsChecked(true);
        }
      } catch (error) {
        console.error("중복 체크 오류:", error);
      }
    }
  };

  // 비즈니스 이메일 입력 상태 변경 함수
  const handleBusinessInputChange = (e) => {
    setBusinessEmailCheck(e.target.value);
  };

  // 뉴스레터・이벤트・혜택 정보 수신
  const handleCheckboxChange = () => {
    setIsSendEmailChecked(!isSendEmailChecked); // 체크 상태 반전
  };

  // 이름 입력 상태 변경 함수
  const handleNameInputChange = (e) => {
    setName(e.target.value);
  };

  // 소속 기관 선택 상태 변경 함수
  const handleSelectChange = (e) => {
    setOrganizationType(e.target.value);
  };

  // 소속 기관 찾기 팝업
  const findOrg = () => {
    window.open("/popup/searchOrg", "소속 기관(기업) 검색", "width=1000px,height=700px,top=200,resizable=no");
  };

  // 소속 기관명 클릭 시 팝업 열기
  const handleOrgInputClick = () => {
    if(organizationType !== "없음(개인)"){
      findOrg();
    }
  };

  // 팝업에서 호출할 함수
  window.handlePopupData = (orgObject) => {
    setOrgName(orgObject.org_name);
    setOrgSeq(orgObject.org_seq);

    console.log("팝업에서 전달받은 데이터:", orgObject);
  };

  // 가입 신청
  const signUp = async () => {
    const data = {
      email: emailCheck,
      name: name,
      password: emailCheck, // Trial ID 생성 시 이메일을 비밀번호로 사용
      org_seq: orgSeq,
      org_type: organizationType,
      platform: "TRIAL",
      file_name: "", // 해당 data는 사용하지 않음
      org_name: orgName,
      bizEmail: businessEmailCheck,
      agreement: isSendEmailChecked
    };

    try {
      const response = await axios.post('/api/admin/signUp', data);

      if(response.status === 200){
        alert("가입 신청이 완료됐습니다.");
        history.goBack();
      }

    } catch (error) {
    }
  };


  const validityCheck = () => {
    // 아이디가 입력되지 않았을 때
    if (emailCheck === "") {
      setCheckResult("아이디(이메일)를 입력해주세요.");
    }
    else if (isChecked === false) {
      setCheckResult("아이디(이메일) 중복 체크를 진행해주세요");
    }
    else if (businessEmailCheck !== "" && !emailRegex.test(businessEmailCheck)) {
      setNameWarning("");
      setOrgWarning("");
      setBusinessEmailWarning("비즈니스 이메일 형식이 올바르지 않습니다.\n(사용하지 않으면 공란으로 비워두세요)");
    }
    else if (name === "") {
      setBusinessEmailWarning("");
      setOrgWarning("");
      setNameWarning("이름을 입력하세요.");
    }
    else if (organizationType === "" || (organizationType !== "없음(개인)" && orgName === "")) {
      setBusinessEmailWarning("");
      setNameWarning("");
      setOrgWarning("소속 기관(성격, 기관명)을 올바르게 입력하세요.\n(소속 기관이 없을 경우 소속기관 성격에'없음(개인)'으로 선택해주세요)");
    } else {
      // signUp();
      alert("가입 신청이 완료됐습니다.");
      history.goBack();
    }
  };

  return (
    <J.TotalDiv>
      <J.InnerTotalDiv>
          <J.TitleH4>Trial ID 생성</J.TitleH4>
        <J.NoticeDiv>
          <J.EssentialStar>*</J.EssentialStar> 표시는 필수항목입니다.
        </J.NoticeDiv>

        <J.BasicDiv>
          <J.BasicLabel>
            아이디(이메일)<J.EssentialStar>*</J.EssentialStar>
          </J.BasicLabel>
          <div>
            <J.IdInputLineDiv>
            <J.IdInput
              type="text"
              placeholder="example@techdna.kr"
              value={emailCheck}
              onChange={handleInputChange}
            />
            {isChecked ? (
              <J.CheckBoxInput type="checkbox" checked={isChecked} readOnly />
            ) : (
              <J.DuplCheck type="button" onClick={handleDuplicateCheck}>
                중복체크
              </J.DuplCheck>
            )}
            </J.IdInputLineDiv>
          </div>
          <J.WarningP>※ 가입 후에는 변경할 수 없습니다.</J.WarningP>
          <J.CheckResultP>{checkResult}</J.CheckResultP>
        </J.BasicDiv>

        <J.BasicDiv>
          <J.BasicLabel>비즈니스 이메일</J.BasicLabel>

          <J.Input
            type="text"
            placeholder="example@techdna.kr"
            value={businessEmailCheck}
            onChange={handleBusinessInputChange}
          />
          <J.CheckResultP>{businessEmailWarning}</J.CheckResultP>

          <J.EmailCheckDiv>
            <J.EmailCheckLine>
              <J.EmailCheckInput
                type="checkbox"
                checked={isSendEmailChecked}
                onChange={handleCheckboxChange}
              />
              <J.SelectSpan>[선택]</J.SelectSpan>
              <span>뉴스레터・이벤트・혜택 정보 수신</span>
            </J.EmailCheckLine>
            <J.AboutEmailCheckP>
              ResearchALL 서비스 및 뉴스레터・제휴 이벤트・혜택 등의 정보를
              이메일로 받을 수 있습니다. 수신에 대해 별도로 안내드리며 동의를
              구합니다.
            </J.AboutEmailCheckP>
          </J.EmailCheckDiv>
        </J.BasicDiv>

        <J.BasicDiv>
          <J.BasicLabel>
            이름<J.EssentialStar>*</J.EssentialStar>
          </J.BasicLabel>
          <J.Input
            type="text"
            placeholder="홍길동"
            value={name}
            onChange={handleNameInputChange}
          />
          <J.CheckResultP>{nameWarning}</J.CheckResultP>
        </J.BasicDiv>

        <J.BasicDiv>
          <J.BasicLabel>
            소속 기관 성격<J.EssentialStar>*</J.EssentialStar>
          </J.BasicLabel>
          <J.SelectOrgType
            value={organizationType} // 선택된 값으로 상태 반영
            onChange={handleSelectChange} // 값이 변경될 때 상태 업데이트
          >
            <option value="">기관 성격을 선택해 주세요</option>
            <option value="기업">기업</option>
            <option value="공공기관">공공기관</option>
            <option value="학교">학교</option>
            <option value="연구소">연구소</option>
            <option value="특허법인">특허법인</option>
            <option value="기타">기타</option>
            <option value="없음(개인)">없음(개인)</option>
          </J.SelectOrgType>
        </J.BasicDiv>

        <J.BasicDiv>
          <J.BasicLabel>
            소속기관명<J.EssentialStar>*</J.EssentialStar>
          </J.BasicLabel>
          {
            orgName === "" ? (
            (organizationType === "없음(개인)"? (
              <J.InactiveInput
                readOnly
                type="text"
                placeholder="없음(개인)을 선택하셨습니다."
              />
              ) : (
              <J.Input
                readOnly
                type="text"
                placeholder="소속이 없을 경우, 기관 성격을 없음(개인)으로 선택하세요."
                onClick={handleOrgInputClick}
              />))
            ) : (
              <J.Input
                readOnly
                type="text"
                value={orgName}
                onClick={handleOrgInputClick}/>
            )
          }
          <J.CheckResultP>{orgWarning}</J.CheckResultP>
        </J.BasicDiv>

        <J.SubmitBtn
          onClick={validityCheck}>
          가입신청
        </J.SubmitBtn>
      </J.InnerTotalDiv>
    </J.TotalDiv>
  );

}

export default JoinNew;