import React, { useEffect, useState } from "react";
import axios from "axios";
import SM from "../../styled-components/subMemberPopup"

import {
  Button
} from "@material-ui/core";

function SubMember({ closePopup, perItem, perInfo, btnStatus }) {
  const [inputEmail, setInputEmail] = useState("");
  const [warning, setWarning] = useState("");

  // 이메일 정규식
  const emailRegex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");

  // TotalDiv 클릭 시 팝업 닫기
  const handleClosePopup = () => {
    closePopup();
  };

  // innerTotalDiv 클릭 시 팝업 닫히지 않도록
  const handleInnerClick = (e) => {
    e.stopPropagation(); // 부모 컴포넌트로 이벤트 전파를 막음
  };

  const deletePlanMember = async () => {
    const memberSeq = perInfo.member_seq;
    console.log("perInfo.member_seq", perInfo.member_seq);

    try {
      const response = await axios.put('/api/plan/deleteSubscription', null, {
        params: { member_seq: memberSeq },
      });

      if(response.status === 200){
        alert("정상 처리되었습니다.");
        handleClosePopup();
      }
    } catch (error) {
      console.error('Error with PUT request:', error.response?.data || error.message);
    }
  };

  const registerSubscription = async (memberSeq) => {

    console.log("item.grant_code", perItem.grant_code);
    console.log("memberSeq", memberSeq);

    try {
      // API 호출
      const response = await axios.get('/api/plan/registerSubscription', {
        params: {
          coupon_code: "harimTest",
          member_seq: 1553,
        },
      });

      const message = response.data.data.message;

      if (message === "쿠폰 번호가 일치하지 않거나, 사용이 만료되었습니다.") {
        setWarning(message);
      }
      else {
        alert("등록되었습니다.");
        handleClosePopup();
      }
    } catch (error) {
      console.error('Error with PUT request:', error.response?.data || error.message);
    }
  };

  // 이메일 입력 상태 변경 함수
  const handleInputChange = (e) => {
    setInputEmail(e.target.value);
  };

  const searchEmail = async () => {

    const email = inputEmail;

    if (!emailRegex.test(email)) {
      setWarning("이메일 형식이 올바르지 않습니다.");
      return
    }

    try {
      // API 호출
      const response = await axios.get('/api/plan/getMemberSeq', {
        params: { email }, // 쿼리 파라미터 전달
      });

      const status = response.status;
      const memberSeq = response.data.data;

      console.log(response);
      if (status === 200) {
        registerSubscription(memberSeq);
      }
    }
    catch (error) {
      setWarning("회원 번호 조회에 실패하였습니다.");
    }
  };

  // test
  useEffect(() => {
    console.log(perItem);
    console.log(perInfo);
  }, []);

  return (
    <SM.TotalDiv onClick={handleClosePopup}>
      <SM.InnerTotalDiv onClick={handleInnerClick}>
        <SM.CloseDiv>
          <SM.CloseButton onClick={handleClosePopup}>
            <SM.Line/>
            <SM.Line/>
          </SM.CloseButton>
        </SM.CloseDiv>
        {btnStatus === "제거" ? (
          <>
            <SM.TitleH1>플랜 제거</SM.TitleH1>
            <SM.DescriptionP>
              {perInfo.name} 님의 {perItem.note} 플랜을 제거합니다.
            </SM.DescriptionP>
            <SM.BtnDiv>
              <Button style={{backgroundColor:"#EA6A6A", color: "white", fontWeight: 'medium', boxShadow: 'none'}} onClick={handleClosePopup}>아니요</Button>
              <Button style={{backgroundColor:"#3085D6", color: "white", fontWeight: 'medium', boxShadow: 'none'}} onClick={deletePlanMember}>예</Button>
            </SM.BtnDiv>
          </>
        ) : (
          <>
            <SM.TitleH1>플랜 설정</SM.TitleH1>
            <SM.ExplainP>플랜을 적용 할 E-mail을 입력해주세요.</SM.ExplainP>
            <SM.OtherP>
              플랜은 ResearchALL 의 모든 기능을 정해진 기간에 따라 사용
              가능합니다.
            </SM.OtherP>

            <SM.InputLabel>적용할 회원 이메일</SM.InputLabel>
            <SM.Input
              type="text"
              placeholder="이메일 입력"
              value={inputEmail}
              onChange={handleInputChange}
            />
            <SM.WarningH6>{warning}</SM.WarningH6>
            <Button style={{backgroundColor:"#3085D6", color: "white", fontWeight: 'medium', boxShadow: 'none'}}  onClick={searchEmail}>추가</Button>
          </>
        )}
      </SM.InnerTotalDiv>
    </SM.TotalDiv>
  );
}

export default SubMember;