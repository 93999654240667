import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Pagination from "react-js-pagination";
import "../../../components/Style/Table.css";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  Button,
} from "@material-ui/core";
import axios from "axios";
import SubMember from "../../../components/PopUp/subMember";

export default function CouponListTable(props) {
  const row = ["NO", "코드명", "상품명", "사용 가능 인원", "가격", "시작일", "종료일"];
  const totalData = props.totalData;
  const [dataCnt, setDataCnt] = useState(0);

  // pagination
  const [page, setPage] = useState(1);
  const [currentPost, setCurrentPost] = useState([]);
  const postPerPage = 10;
  const indexOfLastPost = page * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;

  // subMember
  const [expandedRows, setExpandedRows] = useState({}); // 클릭된 행과 관련 데이터 저장
  const [popupOpen, setPopupOpen] = useState(false);

  const [perItem, setPerItem] = useState([]);
  const [perInfo, setPerInfo] = useState([]);

  const [btnStatus, setBtnStatus] = useState("");

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    if (totalData && totalData.length > 0) {
      setCurrentPost(totalData.slice(indexOfFirstPost, indexOfLastPost));
      setDataCnt(totalData.length);
    } else {
      setDataCnt(1);
    }
  }, [totalData, page]);

  const getMoreInfo = async (paid_seq) => {
    try {
      const response = await axios.get(`/api/admin/subMember?paid_seq=${paid_seq}`);
      console.log(response.data.data);
      return response.data.data; // 데이터를 반환
    } catch (error) {
      console.error("소속 기관 검색 함수 오류:", error);
      return [];
    }
  };

  const handleRowClick = async (rowPaidSeq) => {
    if (expandedRows[rowPaidSeq]) {
      // 이미 열려 있다면 닫기
      setExpandedRows((prev) => {
        const updatedRows = { ...prev };
        delete updatedRows[rowPaidSeq];
        return updatedRows;
      });

      console.log(rowPaidSeq);
    } else {
      // 새 데이터를 가져와서 열기
      const data = await getMoreInfo(rowPaidSeq);
      setExpandedRows((prev) => ({ ...prev, [rowPaidSeq]: data }));
    }
  };

  const handleSubMemberBtn = () => {
    setPopupOpen(true);
  }

  return (
    <>
      <TableContainer>
        
        <Table style={{ tableLayout: "fixed", width: "100%", cursor: "pointer" }}>
          <TableHead>
            <TableRow>
              {row &&
                row.map((headerItem) => (
                  <TableCell style={{ textAlign: "center" }} key={headerItem}>
                    {headerItem}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPost &&
              currentPost.map((item) => (
                <React.Fragment key={item.num}>
                  <TableRow
                    onClick={() => handleRowClick(item.paid_seq)} // 클릭 시 API 호출 및 상태 업데이트
                  >
                    <TableCell style={{ textAlign: "center" }}>
                      {item.paid_seq}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {item.grant_code}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {item.note}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {item.use_member_cnt}/{item.usable_member_cnt}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {item.price}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {item.issue_date}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {item.end_date}
                    </TableCell>
                  </TableRow>
                  {expandedRows[item.paid_seq] &&
                    (expandedRows[item.paid_seq].length > 0 ? (
                      <Table style={{ tableLayout: "fixed", width: "81.6vw", cursor: "default" }}>
                        {/* 테이블 헤더 */}
                        <TableHead style={{ backgroundColor: "#F1F1F1"}}>
                          <TableRow>
                            <TableCell style={{ textAlign: "center" }}>사람 수</TableCell>
                            <TableCell style={{ textAlign: "center" }}>이름</TableCell>
                            <TableCell style={{ textAlign: "center" }}>이메일</TableCell>
                            <TableCell style={{ textAlign: "center" }}>회사</TableCell>
                            <TableCell style={{ textAlign: "center" }}>옵션</TableCell>
                          </TableRow>
                        </TableHead>

                        {/* 테이블 바디 */}
                        <TableBody style={{ backgroundColor: "#FCFCFC" }}>
                          {expandedRows[item.paid_seq].map((info, index) => (
                            <TableRow key={index}>
                              <TableCell style={{ textAlign: "center" }}>{index + 1}</TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {info.name || "없음"}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {info.email || "없음"}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {info.org_name || "없음"}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {info.name ? (
                                  <Button style={{backgroundColor:"#EA6A6A", color: "white", fontWeight: 'medium', boxShadow: 'none'}} variant="contained"
                                    onClick={() => {
                                      setPerItem(item);
                                      setPerInfo(info);
                                      setBtnStatus("제거");
                                      handleSubMemberBtn();
                                    }}
                                  >
                                    제거
                                  </Button>
                                ) : (
                                  <Button style={{backgroundColor:"#3085D6", color: "white", fontWeight: 'medium', boxShadow: 'none'}} variant="contained"
                                    onClick={() => {
                                      setPerItem(item);
                                      setPerInfo(info);
                                      setBtnStatus("추가");
                                      handleSubMemberBtn();
                                    }}
                                  >
                                    추가
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                        <TableCell colSpan={7} style={{ textAlign: "center", backgroundColor: "#FCFCFC"}}>
                          추가 정보 없음
                        </TableCell>
                  ))}
                  
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
        
        <RealTableFooter style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            activePage={page}
            itemsCountPerPage={postPerPage}
            totalItemsCount={dataCnt}
            pageRangeDisplayed={5}
            prevPageText={"<"}
            nextPageText={">"}
            onChange={handlePageChange}
          />
        </RealTableFooter>
      </TableContainer>
      {popupOpen ? <SubMember closePopup={() => setPopupOpen(false)} perItem={perItem} perInfo={perInfo} btnStatus={btnStatus}/> : null}
    </>
  );
}

const RealTableFooter = styled(TableFooter)`
    display: flex;
    justify-content: center;
    text-align: center;
`;
