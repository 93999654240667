import React, { useState, useEffect, useRef } from "react";
import { GoMoveToStart, GoChevronLeft, GoChevronRight, GoMoveToEnd } from "react-icons/go";
import TableRow from "./table_row";
import axios from "axios";
import S from "../../styled-components/searchOrgPopup"

function PopupSearchOrg() {

  // 소속 기관 고유 번호 (부모에게 전달용)
  const [orgSeq, setOrgSeq] = useState(0);
  // 소속 기관명 (부모에게 전달용)
  const [orgName, setOrgName] = useState("");
  // 사용자 입력 기관
  const [inputOrg, setInputOrg] = useState("");
  // 소속 기관 데이터를 한 페이지 단위로 담는 배열
  const [organizations, setOrganizations] = useState([]);
  // 검색된 건수
  const [totalOrgs, setTotalOrgs] = useState(0);

  // 페이지네이션 관련 상태
  const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수
  const [currentPage, setCurrentPage] = useState(0); // 현재 페이지

  const pagesPerGroup = 5; // 한 그룹에 표시할 페이지 수 ex) 1, 2, 3, 4, 5
  const [totalGroups, setTotalGroups] = useState(0); // 전체 페이지 그룹 수
  const [currentGroup, setCurrentGroup] = useState(0); // 현재 페이지 그룹
  const [pageNumbers, setPageNumbers] = useState([]); // 현재 페이지 그룹의 번호 목록

  // 특정 페이지로의 이동을 위한 ref
  const inputPageRef = useRef(null);

  // 사용자 입력 기관명 변경 함수
  const handleInputOrgChange = (e) => {
    setInputOrg(e.target.value);
  };

  // 버튼 클릭 이벤트 처리 함수
  const handleButtonClick = (option) => {
    switch (option) {
      case "search":
        return handleSearchOrg("search");
      case "directInput":
        return handleInputOrg("directInput");
      default:
        return null;
    }
  };

  // 소속 기관 검색 함수
  const handleSearchOrg = async (props) => {

    const text = inputOrg;

    let page = currentPage;
    if (props === "search") {
      page = 0;
    }

    const size = 10;

    try {
      const response = await axios.get('/api/admin/searchOrg?text=' + text + '&page=' + page + '&size=' + size);
      // console.log(response);
      setOrganizations(response.data.data.organization);

      // 첫 페이지일 경우 전체 건수와 페이지네이션 관련 값 설정
      if (page === 0) {
        const total_value = response.data.data.total_value;
        setTotalOrgs(total_value);

        // 전체 페이지 수 계산
        const total_pages = Math.ceil(total_value / size); // 반올림
        setTotalPages(total_pages);

        // 전체 페이지 그룹 수 계산
        const total_groups = Math.ceil(total_pages / pagesPerGroup);
        setTotalGroups(total_groups);
      }

      if (props === "search") {
        setCurrentPage(0); // 검색 시 첫 페이지로 이동
        setCurrentGroup(0); // 검색 시 첫 그룹으로 이동
      }

    } catch (error) {
      console.error("소속 기관 검색 함수 오류:", error);
    }
  };

  // 페이지 로딩 시 소속 기관 검색 함수 호출
  useEffect(() => {

    const fetchOrgData = async () => {
      try {
        await handleSearchOrg("pageLoading"); // 비동기 함수 호출
      } catch (error) {
        console.error("소속 기관 검색 중 에러 발생:", error);
      }
    };

    fetchOrgData(); // 비동기 함수 호출

  }, [currentPage]);

  // 소속 기관 입력 함수
  const handleInputOrg = (prop) => {
    let orgObject;

    if (prop === "search") {
      orgObject = {
        org_seq: orgSeq,
        org_name: orgName,
      };
    }
    else if (prop === "directInput") {
      orgObject = {
        org_seq: 0,
        org_name: inputOrg,  // 직접 입력된 조직 이름을 사용
      };
    }

    SendOrgObject(orgObject);
  };
  
  // 부모 창에 접근하여 데이터 전달
  const SendOrgObject = (orgObject) => {

    if (window.opener) {
      window.opener.handlePopupData(orgObject); // 부모 창에 정의된 함수 호출
      window.close(); // 팝업 닫기
    }

  };

  // 현재 그룹의 페이지 번호 계산 (currentGroup 또는 totalPages가 변경될 때)
  useEffect(() => {
    // 현재 그룹에서 표시할 페이지 번호 리스트 계산
    const startPage = currentGroup * pagesPerGroup;
    const endPage = Math.min(startPage + pagesPerGroup, totalPages);

    // 페이지 번호 배열 생성 (전체 페이지가 5보다 적어도 문제없음)
    const newPageNumbers = Array.from({ length: endPage - startPage }, (_, i) => startPage + i);
    setPageNumbers(newPageNumbers);
  }, [currentGroup, totalPages]);


// 페이지 변경 함수
  const handlePageChange = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

// 그룹 변경 함수 (이전 그룹 / 다음 그룹)
  const handleGroupChange = (newGroup) => {
    if (newGroup >= 0 && newGroup < Math.ceil(totalPages / pagesPerGroup)) {
      setCurrentGroup(newGroup);
    }
  };

  // 처음으로 버튼
  const goToFirst = () => {
    setCurrentGroup(0);
    setCurrentPage(0);
  };

  // 이전 버튼
  const goToPrev = () => {
    if (currentGroup > 0) {
      const newGroup = currentGroup - 1;
      handleGroupChange(newGroup);
      setCurrentPage((newGroup + 1) * pagesPerGroup - 1); // 이전 그룹의 마지막 페이지로 이동
    } else {
      setCurrentPage(0); // 현재 페이지 그룹이 0일 때 처리
    }
  };

  // 다음 버튼
  const goToNext = () => {
    const maxGroup = Math.max(0, Math.floor((totalPages - 1) / pagesPerGroup));
    if (currentGroup < maxGroup) {
      const newGroup = currentGroup + 1;
      handleGroupChange(newGroup);
      setCurrentPage(newGroup * pagesPerGroup); // 다음 그룹의 첫 페이지로 이동
    }
  };

  // 끝으로 버튼
  const goToLast = () => {
    const lastPage = totalPages > 0 ? totalPages - 1 : 0;
    const lastGroup = Math.floor(lastPage / pagesPerGroup);
    setCurrentGroup(lastGroup);
    setCurrentPage(lastPage);
  };

  // 특정 페이지로 이동하는 함수
  const goToPage = () => {
    const value = inputPageRef.current.value; // input의 현재 값 가져오기

    if (value > 0 && value <= totalPages) {
      setCurrentPage(value - 1); // 0부터 시작하므로 1을 빼줌
      setCurrentGroup(Math.floor((value - 1) / pagesPerGroup)); // 페이지 그룹 계산
    }
    else {
      alert("이동하시려는 페이지 번호를 정확히 입력해주세요.");
    }
  }

  // 테이블에서 행 클릭 시 작동
  const handleRowClick = (prop_orgSeq, prop_orgName) => {
    setOrgSeq(prop_orgSeq);
    setOrgName(prop_orgName);
  };
  
  // 선택 기관(기업)명 선택
  const handleOrgChoiceBtn = () => {
    handleInputOrg("search");
  }

  return (
    <S.TotalDiv>
      <S.InnerTotalDiv>
        <div>
          <S.TitleH4>소속 기관(기업) 검색</S.TitleH4>
        </div>

        <S.BasicDiv>
          <S.InfoDiv>
            ※ 소속 기관(기업)명 또는 사업자번호로{" "}
            <S.SearchSpan>검색</S.SearchSpan> 하신 후 사업자 번호가 일치한
            기관(기업)을 클릭하면 '선택 기관(기업)명'이 입력됩니다.
          </S.InfoDiv>
          <S.InfoDiv>
            이후 <S.SelectSpan>선택</S.SelectSpan> 버튼을 누르시면 회원가입
            창으로 돌아갑니다.
            <td />
            소속 기관(기업)이 검색되지 않을 경우, 기관(기업)명 입력 후{" "}
            <S.InputSpan>직접 입력</S.InputSpan> 버튼을 누르시면 입력 됩니다.
            <td />
            소속되신 기관이 없는 개인이실 경우, 소속기관성격을 '없음(개인)' 으로
            선택해 주시기 바랍니다.
          </S.InfoDiv>
        </S.BasicDiv>

        <S.BasicRowDiv>
          <S.SearchInput
            type="text"
            placeholder="기관(기업명, 또는 사업자 번호)을 입력해주세요"
            value={inputOrg}
            onChange={handleInputOrgChange}
          />
          <S.SearchBtn
            type="button"
            onClick={() => handleButtonClick("search")}
          >
            검색
          </S.SearchBtn>
          <S.InputBtn
            type="button"
            onClick={() => handleButtonClick("directInput")}
          >
            직접 입력
          </S.InputBtn>
        </S.BasicRowDiv>

        <S.BasicDiv>
          <S.Table>
            <S.ColGroup>
              <S.Col />
              <S.Col />
              <S.Col />
            </S.ColGroup>
            <S.Thead>
              <tr>
                <S.Th>No</S.Th>
                <S.Th>기관(기업)명</S.Th>
                <S.Th>사업자 번호</S.Th>
              </tr>
            </S.Thead>
            <S.Tbody>
              {organizations.length > 0 ? (
                organizations.map((org, index) => (
                  <TableRow
                    key={org.org_seq}
                    org={org}
                    onRowClick={handleRowClick}
                  />
                ))
              ) : (
                <tr>
                  <S.NoDataTd colSpan="3">검색 결과 없음</S.NoDataTd>
                </tr>
              )}
            </S.Tbody>
          </S.Table>
        </S.BasicDiv>

        <S.BasicRowDiv>
          <p>
            검색된 건수 : <strong>{totalOrgs}</strong>건
          </p>
          <S.NaviDiv>
            <S.IconDiv>
              <GoMoveToStart size={24} onClick={goToFirst} />
            </S.IconDiv>
            <S.IconDiv>
              <GoChevronLeft size={24} onClick={goToPrev} />
            </S.IconDiv>
            {pageNumbers.map((page) => (
              <S.IconDiv
                key={page}
                onClick={() => handlePageChange(page)}
                style={{
                  fontWeight: currentPage === page ? "bold" : "normal",
                }}
              >
                {page + 1}
              </S.IconDiv>
            ))}
            <S.IconDiv>
              <GoChevronRight size={24} onClick={goToNext} />
            </S.IconDiv>
            <S.IconDiv>
              <GoMoveToEnd size={24} onClick={goToLast} />
            </S.IconDiv>
          </S.NaviDiv>
          <div>
            <S.PageInput
              type="number"
              placeholder="페이지"
              min="1"
              max={totalGroups}
              step="1"
              ref={inputPageRef}
            />
            <S.MoveToBtn onClick={goToPage}>이동</S.MoveToBtn>
          </div>
        </S.BasicRowDiv>

        <S.RightRowDiv>
          <label htmlFor="text">선택 기관(기업)명</label>
          <S.SubmitInput type="search" readOnly value={orgName} />
          <S.SubmitBtn type="button" onClick={handleOrgChoiceBtn}>
            선택
          </S.SubmitBtn>
        </S.RightRowDiv>
      </S.InnerTotalDiv>
    </S.TotalDiv>
  );
}

export default PopupSearchOrg;