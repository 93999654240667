// 리서치 올 대시 보드 페이지
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/ko'
import {
  Cell,
  Pie,
  PieChart,
  XAxis,
  YAxis,
  LineChart,
  Line,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  ResponsiveContainer,
  Label,
} from "recharts";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import axios from "axios";
import EndGradeMemTable from "./components/EndGradeMemTable";
import DirectTable from "./components/DirectTable";
import NewPlanMemTable from "./components/NewPlanMemTable";
import dayjs from 'dayjs'

function changeData(arr, index1){
  arr.forEach((item) => {
    const value1 = item[index1];
    item[index1] = value1.split('T')[0];
  })
  return arr;
}

// 전체회원 org_type 데이터 머지
function mergeData(arr){
  let arr4 = [];
  arr.forEach((item) => {
    const arr1 = item[0];
    const arr2 = item[1];
    const arr3 = item[2];
    for(let i=0; i<7; i++){
      let ret = {
        date: arr1[i],
        totalMember: arr2[i],
        subscribeMember: arr3[i]
      }
      arr4.push(ret);
    }
  })
  return arr4;
}

//프로젝트 그래프 커스텀 툴팁
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div
        style={{
          padding: "6px",
          backgroundColor: "white",
          border: "1px solid grey"
        }}
      >
        <b style={{ marginBottom: "10px"}}>{payload[0].payload.date.date}</b>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "10px" }}>
            <b>전체 회원: {payload[0].payload.totalMember.공공기관 + payload[0].payload.totalMember.기업 + payload[0].payload.totalMember.연구소 + payload[0].payload.totalMember.특허법인 + payload[0].payload.totalMember.학교 + payload[0].payload.totalMember.기타}</b>
            <p>공공기관: {payload[0].payload.totalMember.공공기관}</p>
            <p>기업: {payload[0].payload.totalMember.기업}</p>
            <p>연구소: {payload[0].payload.totalMember.연구소}</p>
            <p>특허법인: {payload[0].payload.totalMember.특허법인}</p>
            <p>학교: {payload[0].payload.totalMember.학교}</p>
            <p>기타: {payload[0].payload.totalMember.기타}</p>
          </div>
          <div>
            <b>플랜 회원: {payload[0].payload.subscribeMember.공공기관 + payload[0].payload.subscribeMember.기업 + payload[0].payload.subscribeMember.연구소 + payload[0].payload.subscribeMember.특허법인 + payload[0].payload.subscribeMember.학교 + payload[0].payload.subscribeMember.기타}</b>
            <p>공공기관: {payload[0].payload.subscribeMember.공공기관}</p>
            <p>기업: {payload[0].payload.subscribeMember.기업}</p>
            <p>연구소: {payload[0].payload.subscribeMember.연구소}</p>
            <p>특허법인: {payload[0].payload.subscribeMember.특허법인}</p>
            <p>학교: {payload[0].payload.subscribeMember.학교}</p>
            <p>기타: {payload[0].payload.subscribeMember.기타}</p>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

function ResearchDashboard(){
  const today = new Date();
  const dateStringToday = new Date(today.setDate(today.getDate())); // 사용자의 로컬 타임존으로 적용
  const todayFormat = dayjs(dateStringToday.toDateString()); // 사용자의 로컬 타임존으로 적용

  const [memberStartDateValue,setMemberStartDateValue] = useState(todayFormat);
  const [projectStartDateValue, setProjectStartDateValue] = useState(todayFormat);
  // 매출 select value
  const [selectedValue, setSelectedValue] = useState(1);
  // 회원 수 그래프 select value
  const [memberSelectedValue, setMemberSelectedValue] = useState(1);
  // 프로젝트 수 그래프 select value
  const [projectSelectedValue, setProjectSelectedValue] = useState(2);
  const [projectGraphList, setProjectGraphList] = useState();
  const [memberList, setMemberList] = useState();
  const [subMemberList, setSubMemberList] = useState();
  const [newPlanMemberList, setNewPlanMemberList] = useState();
  const [filteredMember, setFilteredMember] = useState([]);
  const [monthPaymentData, setMonthPaymentData] = useState([]);
  const [yearPaymentData, setYearPaymentData] = useState([]);
  const [yearPaymentDataKey, setYearPaymentDataKey] = useState([]);
  const [monthGradeMember, setMonthGradeMember] = useState([]);
  const [dailyGradeMember, setDailyGradeMember] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [directList, setDirectList] = useState([]);
  const [waitAppliData, setWaitAppliData] = useState([]);
  const [totalProject, setTotalProject] = useState([]);
  const [appliProject, setAppliProject] = useState([]);
  const [lastWeekTotalProject, setLastWeekTotalProject] = useState([]);
  const [lastWeekAppliProject, setLastWeekAppliProject] = useState([]);
  const COLORS = [
    "#FF5E98", // 핑크
    "#4C97E8", // 파랑
    "#9076CB", // 보라
    "#7CCB76", // 연두
    "#FF8962", // 오렌지
    "#FFB84D", // 황금색
    "#52D8A8", // 민트
    "#FF6F61", // 진한 핑크
    "#8D6E63", // 갈색
    "#2C6E93", // 파란색
    "#F5C300", // 노랑
    "#66BB6A", // 초록
  ];
  const row = ["이름","등급","메일","소속","플랜 종료일"];
  const row2 = ["제목", "이름", "소속","문의일"];

  // 전체 회원 org_type
  useEffect(() => {

    if(memberStartDateValue){
      axios.get("/api/member/memberGraph?term=" + memberSelectedValue + "&startDate=" + memberStartDateValue)
        .then((response) => {
          // console.log(response.data.data)
          const memberData = mergeData([response.data.data])
          setSubMemberList(memberData)
        })
    }

  },[memberStartDateValue, memberSelectedValue])

  // 프로젝트 그래프 api
  useEffect(() => {
    if(projectStartDateValue){
      axios.get("/api/projects/projectGraph?term=" + projectSelectedValue + "&startDate=" + projectStartDateValue)
        .then((response) => {
          // console.log(response)
          setProjectGraphList(response.data.data)
        })
    }
  },[projectStartDateValue, projectSelectedValue])

  // 총/구독 회원 api
  useEffect(() => {
    axios.get("/api/member/list")
      .then((response) => {
        const memberData = response.data.data.member;
        const dailyData = [];
        for(let i = 6; i>=0; i--){
          const currentDate = new Date();
          currentDate.setDate(today.getDate()-i);
          const filteredData = memberData.filter((item)=> new Date(item.sub_data) <= currentDate);
          const subData = memberData.filter((item => item.end_date !== null))
          dailyData.push({
            date: currentDate.toLocaleDateString('ko-KR', {year: undefined , month: 'long', day: 'numeric'}),
            totalMember: filteredData.length,
            subscribeMember: subData.length,
          });
        }
        setMemberList(memberData);
        setFilteredMember(dailyData);
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);

  // 등급 별 회원 수(주) api
  useEffect(() => {
    axios.get("/api/member/gradeMemberWeek")
      .then((response) => {
        const gradeMemAPIData = response.data.data;
        const dailyData = [];
        const keys = [];

        for (let i = 5; i >= 1; i--) {
          const weekData = {
            date: i - 1 + " weeks ago"
          };

          // gradeMemAPIData 길이에 맞춰서 동적으로 key를 추가
          gradeMemAPIData.forEach((data, index) => {
            const gradeName = data.grade_name;
            const sumValue = data[`sum(w${i})`];
            weekData[gradeName] = sumValue;

            // keys 배열에 중복 없이 grade_name 추가
            if (!keys.includes(gradeName)) {
              keys.push(gradeName);
            }
          });

          dailyData.push(weekData);
        }

        setMonthGradeMember(dailyData);
        setYearPaymentDataKey(keys);

      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // 신규 플랜 회원 목록 api
  useEffect(() => {
    axios.get("/api/member/newPlanMemList")
      .then((response) => {
        // console.log(response.data.data)
        setNewPlanMemberList(response.data.data)
    })
  }, [])

  // 등급별 구독 회원 수(당일)api
  useEffect(() => {
    axios.get("/api/member/gradeMemberDaily")
      .then((response) => {
        // console.log(response.data.data)
        const gradeMemAPIData = response.data.data;
        const dailyData = [];

        // length 속성은 배열에서 바로 사용
        for (let i = 0; i < gradeMemAPIData.length; i++) {  // 배열의 인덱스는 0부터 시작
          if (gradeMemAPIData[i].value > 0) {
            dailyData.push(gradeMemAPIData[i]);
          }
        }

        setDailyGradeMember(dailyData);

      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // 종료 예정 구독 회원 api
  useEffect(() => {
    axios.get("/api/member/endGradeMember")
      .then((response) => {
        const endMemberAPIData= changeData([...response.data.data],"end_date");
        setTableList(endMemberAPIData);
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);


  //월 수익 api
  useEffect(() => {
    axios.get("/api/payment/totalMonthPayment")
      .then((response) => {
        // console.log(response.data.data)
        const paymentAPIData = response.data.data;
        setMonthPaymentData(paymentAPIData.slice(-6))
      })
      .catch(function(error){
        console.log(error)
      });
  }, [selectedValue]);
  // 년 수익 api
  useEffect(() => {
    axios.get("/api/payment/totalYearPayment")
      .then((response) => {
        // console.log(response)
        const paymentAPIData = response.data.data;
        setYearPaymentData(paymentAPIData.slice(-6))
      })
      .catch(function(error){
        console.log(error)
      });
  }, [selectedValue]);
  const getPaymentData = () => {
    if(selectedValue == 1){
      return monthPaymentData;
    } else {
      return yearPaymentData;
    }
  }
  // 매출 월/년 선택 변경
  const paymentValueChange = (event) => {
    setSelectedValue(event.target.value);
  }
  // 회원수 월/년/일 선택 변경
  const memberValueChange = (event) => {
    setMemberSelectedValue(event.target.value)
  }
  // 프로젝트 수 월/년/일 선택 변경
  const projectValueChange = (event) => {
    setProjectSelectedValue(event.target.value)
  }
  const formatYAxis = (yTick) => {
    return `${yTick.toLocaleString()}`
  }
  const formatToolTip = (value) => {
    return `${value.toLocaleString()}`
  }

  // 1:1 문의 api
  useEffect(() => {
    axios.get("/api/memberCenter/weekDirectList")
      .then((response) => {
        // console.log(response.data.data)
        const directAPIData = changeData([...response.data.data],"request_date");
        setDirectList(directAPIData);
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);

  // 승인 대기 정비 수
  useEffect(() => {
    axios.get("/api/applicant/assigneeHistory?search_type=TOTAL")
      .then((response) => {
        const applicantAPIData = response.data.data
        const waitApplicantData = applicantAPIData.filter(item => item.change_flag == 0)
        setWaitAppliData(waitApplicantData);
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);

  // 특허 프로젝트
  useEffect(() => {
    axios.get("/api/projects/list")
      .then((response) => {
        // console.log(response.data.data)
        const projectAPI = response.data.data
        const currentDate = new Date();
        currentDate.setDate(today.getDate()-7);
        const filteredData = projectAPI.filter((item)=> new Date(item.created_at) <= currentDate);
        setLastWeekAppliProject(filteredData);
        setAppliProject(projectAPI);
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);

  // 통합 프로젝트
  useEffect(() => {
    axios.get("/api/projects/totalList")
      .then((response) => {
        // console.log(response.data.data)
        const projectAPI = response.data.data
        const currentDate = new Date();
        currentDate.setDate(today.getDate()-7);
        const filteredData = projectAPI.filter((item)=> new Date(item.created_time) <= currentDate);
        setLastWeekTotalProject(filteredData);
        setTotalProject(projectAPI);
      })
      .catch(function(error){
        console.log(error)
      });
  }, []);


  return(
    <div>
      <PageTitle title="Dashboard" />
      <Grid container spacing={4}>
        <Grid item lg={2}>
          <Widget title="전체 회원 수">
            <Grid style={{ display: 'flex'}} >
              <Typography variant="h2" style={{fontWeight:'bold'}}>{memberList?.length.toLocaleString() || 0}</Typography>
              <Typography style={{color:(filteredMember[6] && filteredMember[0] ? (filteredMember[6].totalMember - filteredMember[0].totalMember) : 0) > 0 ? '#FF6B7E' : '#6BB9FF', fontWeight:'bold'}}>&nbsp;{filteredMember[6] && filteredMember[0] ? ((filteredMember[6].totalMember - filteredMember[0].totalMember) > 0 ? '+' : '') + (filteredMember[6].totalMember - filteredMember[0].totalMember) : 0}</Typography>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={2}>
          <Widget title="플랜 회원 수">
            <Grid style={{display: 'flex'}}>
              <Typography variant="h2" style={{fontWeight:'bold'}}>{filteredMember[6]?.subscribeMember.toLocaleString() || 0}</Typography>
              <Typography style={{color:(filteredMember[0] && filteredMember[6] ? (filteredMember[6].subscribeMember - filteredMember[0].subscribeMember) : 0) > 0 ? '#FF6B7E' : '#6BB9FF', fontWeight:'bold'}}>&nbsp;{filteredMember[0] && filteredMember[6] ? ((filteredMember[6].subscribeMember - filteredMember[0].subscribeMember) > 0 ? '+' : '') + (filteredMember[6].subscribeMember - filteredMember[0].subscribeMember) : 0}</Typography>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={2}>
          <Widget title="통합 프로젝트 수">
            <Grid style={{display: 'flex'}}>
              <Typography variant="h2" style={{fontWeight:'bold'}}>{totalProject?.length.toLocaleString() || 0}</Typography>
              <Typography style={{color:(totalProject && lastWeekTotalProject ? (totalProject.length - lastWeekTotalProject.length) : 0) > 0 ? '#FF6B7E' : '#6BB9FF', fontWeight:'bold'}}>&nbsp;{totalProject && lastWeekTotalProject ? ((totalProject.length - lastWeekTotalProject.length) > 0 ? '+' : '') + (totalProject.length - lastWeekTotalProject.length) : 0}</Typography>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={2}>
          <Widget title="특허 프로젝트 수">
            <Grid style={{display: 'flex'}}>
              <Typography variant="h2" style={{fontWeight:'bold'}}>{appliProject?.length.toLocaleString() || 0}</Typography>
              <Typography style={{color:(appliProject && lastWeekAppliProject ? (appliProject.length - lastWeekAppliProject.length) : 0) > 0 ? '#FF6B7E' : '#6BB9FF', fontWeight:'bold'}}>&nbsp;{appliProject && lastWeekAppliProject ? ((appliProject.length - lastWeekAppliProject.length) > 0 ? '+' : '') + (appliProject.length - lastWeekAppliProject.length) : 0}</Typography>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={2}>
          <Widget title="1:1 문의 수">
            <Typography variant="h2" style={{fontWeight:'bold'}}>{directList?.length.toLocaleString() || 0}</Typography>
          </Widget>
        </Grid>
        <Grid item lg={2}>
          <Widget title="승인 대기 정비 수">
            <Typography variant="h2" style={{fontWeight:'bold'}}>{waitAppliData?.length.toLocaleString() || 0}</Typography>
          </Widget>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget>
            <div style={{display:"flex"}}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>총 회원 수</Typography>
              <Grid style={{ marginLeft: 10 }}>
                <select className="input_style" onChange={memberValueChange} value={memberSelectedValue}>
                  <option value={1}>Day</option>
                  <option value={2}>Week</option>
                  <option value={3}>Month</option>
                </select>
              </Grid>
            </div>
            <Grid container spacing={1}>
              <div style={{display:"flex"}}>
                <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{monthShort: 'M'}} adapterLocale="ko">
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      format="YYYY-MM-DD"
                      label="Controlled picker"
                      value={memberStartDateValue}
                      onChange={(event) => setMemberStartDateValue(event)}
                      shouldDisableDate={(day) => {
                        const datePickerToday = dayjs().startOf('day');
                        return dayjs(day).isAfter(datePickerToday, 'day');
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              {subMemberList &&
                <ResponsiveContainer width="100%" height={350} debounce={300}>
                  <BarChart data={subMemberList}>
                    <XAxis dataKey="date.date" />
                    <YAxis />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />}/>
                    <Bar type="monotone" stackId="totalMember" dataKey="totalMember.공공기관" fill="#FF5E98" label={{fill: "#ffffff"}} />
                    <Bar type="monotone" stackId="totalMember" dataKey="totalMember.기업" fill="#4C97E8" label={{fill: "#ffffff"}} />
                    <Bar type="monotone" stackId="totalMember" dataKey="totalMember.연구소" fill="#9076CB" label={{fill: "#ffffff"}} />
                    <Bar type="monotone" stackId="totalMember" dataKey="totalMember.특허법인" fill="#7CCB76" label={{fill: "#ffffff"}} />
                    <Bar type="monotone" stackId="totalMember" dataKey="totalMember.학교" fill="#FF8962" label={{fill: "#ffffff"}} />
                    <Bar type="monotone" stackId="totalMember" dataKey="totalMember.기타" fill="#FFB84D" label={{fill: "#ffffff"}} />

                    <Bar type="monotone" stackId="subscribeMember" dataKey="subscribeMember.공공기관" fill="#FF5E98" label={{fill: "#ffffff"}} />
                    <Bar type="monotone" stackId="subscribeMember" dataKey="subscribeMember.기업" fill="#4C97E8" label={{fill: "#ffffff"}} />
                    <Bar type="monotone" stackId="subscribeMember" dataKey="subscribeMember.연구소" fill="#9076CB" label={{fill: "#ffffff"}} />
                    <Bar type="monotone" stackId="subscribeMember" dataKey="subscribeMember.특허법인" fill="#7CCB76" label={{fill: "#ffffff"}} />
                    <Bar type="monotone" stackId="subscribeMember" dataKey="subscribeMember.학교" fill="#FF8962" label={{fill: "#ffffff"}} />
                    <Bar type="monotone" stackId="subscribeMember" dataKey="subscribeMember.기타" fill="#FFB84D" label={{fill: "#ffffff"}} />
                    <Legend payload={[
                      {value: "공공기관", id: "공공기관", color: "#FF5E98"},
                      {value: "기업", id: "기업", color: "#4C97E8"},
                      {value: "연구소", id: "연구소", color: "#9076CB"},
                      {value: "특허법인", id: "특허법인", color: "#7CCB76"},
                      {value: "학교", id: "학교", color: "#FF8962"},
                      {value: "기타", id: "기타", color: "#FFB84D"},
                    ]} />
                  </BarChart>


                </ResponsiveContainer>
              }
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Widget>
            <Grid container spacing={1}>
              <Typography variant="h6" style={{fontWeight:'bold'}}>등급 별 플랜 회원 수</Typography>
              <ResponsiveContainer width="100%" height={350} debounce={300}>
                <LineChart data={monthGradeMember}>
                  <XAxis dataKey="date" />
                  <YAxis/>
                  <Tooltip />
                  <Legend />
                  {yearPaymentDataKey.map((dataKey, index) => (
                    <Line
                      key={dataKey}
                      dataKey={dataKey}
                      stroke={COLORS[index % COLORS.length]}
                      fill={COLORS[index % COLORS.length]}
                      strokeWidth={2}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Widget>
            <div style={{ display: "flex" }}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>프로젝트 관리</Typography>
              <Grid style={{ marginLeft: 10 }}>
                <select className="input_style" onChange={projectValueChange} value={projectSelectedValue}>
                  <option value={1}>Day</option>
                  <option value={2}>Week</option>
                  <option value={3}>Month</option>
                </select>
              </Grid>
            </div>
            <Grid container spacing={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{ monthShort: "M" }} adapterLocale="ko">
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    format="YYYY-MM-DD"
                    label="Controlled picker"
                    value={projectStartDateValue}
                    onChange={(event) => setProjectStartDateValue(event)}
                    shouldDisableDate={day => {
                      return dayjs(day).isAfter(today, "day");
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <ResponsiveContainer width="100%" height={350} debounce={300}>
                <BarChart data={projectGraphList}>
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar type="monotone" dataKey="project" fill="#8884d8" label={{fill: "#ffffff"}} />
                </BarChart>
              </ResponsiveContainer>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <Widget>
            <Grid container spacing={1}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>플랜 회원 등급 관리</Typography>
              <ResponsiveContainer width="100%" height={300} debounce={300}>
                <PieChart>
                  <Pie
                    data={dailyGradeMember}
                    innerRadius={90}
                    outerRadius={130}
                    dataKey="value">
                    {dailyGradeMember.map((_, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
              <Grid style={{ positionRight:0}}>
                {dailyGradeMember.map(({name, value},index)=>(
                  <div style={{ display: "flex", alignItems:"center"}} key={index}>
                    <div
                      style={{
                        width: "10px",  // 필요에 따라 너비와 높이 조절
                        height: "10px",
                        backgroundColor: COLORS[index % COLORS.length],
                        marginRight: "5px",  // 간격을 위한 마진 추가
                      }}
                    />
                    <Typography style={{ whiteSpace: "nowrap", fontSize: 14, color: COLORS[index % COLORS.length], fontWeight:"bold"}}>{name}:&nbsp;</Typography>
                    <Typography style={{ whiteSpace: "nowrap", fontSize: 14, color: COLORS[index % COLORS.length], fontWeight:"bold"}}>&nbsp;{value}&nbsp;&nbsp;</Typography>
                  </div>
                ))}
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={8} md={12} sm={12} xs={12}>
          <Widget>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>매출</Typography>
            <Grid container spacing={1}>
              <ResponsiveContainer width="90%" height={350} debounce={300}>
                <BarChart data={getPaymentData()}>
                  <XAxis dataKey="date" />
                  <YAxis tickFormatter={formatYAxis} width={130} />
                  <Tooltip formatter={formatToolTip} />
                  <Legend />
                  <Bar type="monotone" dataKey="Profit" fill="#3943AC" />
                  <Bar type="monotone" dataKey="accumulateProfit" fill="#6F8EC7" />
                </BarChart>
              </ResponsiveContainer>
              <Grid style={{ marginLeft: 10 }}>
                <select className="input_style" onChange={paymentValueChange} value={selectedValue}>
                  <option value={1}>Month</option>
                  <option value={2}>Year</option>
                </select>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget>
            <Typography variant="h6" style={{fontWeight:'bold'}}>이번주 1:1 문의</Typography>
            <Grid container spacing={1} style={{display: "contents"}}>
              <DirectTable row={row2} totalData={directList} />
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget>
            <Grid style={{ display: "flex" }}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>플랜 종료 예정 회원</Typography>
              <Typography variant="h6">&nbsp;&nbsp;D-14</Typography>
              <Typography variant="h6" style={{color:"#ff3873"}}>&nbsp;&nbsp;총 {tableList?.length || 0}명</Typography>
            </Grid>
            <Grid container spacing={1} style={{display: "contents"}} >
              <EndGradeMemTable row={row} totalData={tableList}/>
            </Grid>
          </Widget>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Widget>
            <Typography variant="h6" style={{fontWeight:'bold'}}>신규 플랜 회원 (일주일 기준)</Typography>
            <Grid container spacing={1} style={{display: "contents"}}>
              <NewPlanMemTable totalData={newPlanMemberList} />
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </div>
  );
}

export default ResearchDashboard;