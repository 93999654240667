import styled from 'styled-components';

export const Btn = styled.button`
    width: 10vw;
    height: 2.8vw;

    margin-bottom: 1vw;

    background-color: #536DFE;
    font-size: 0.8vw;
    color: white;

    border: none; /* 테두리 제거 */
    border-radius: 0.5vw; /* 모서리 둥글게 */
    cursor: pointer; /* 버튼에 마우스 올릴 때 포인터 커서 */

    &:hover {
        background-color: #4154d0; /* 호버 시 색상 변경 */
    }
`;
