// 팝업 CSS이기 때문에 픽셀 단위가 적절

import styled from 'styled-components';

const S = {
  TotalDiv: styled.div`
      width: 100vw;
      height: 100%;
      
      position: relative;
      
      display: flex;
      justify-content: center;
      align-items: center;
      
      margin: 0vw 0vw;
  `,

  InnerTotalDiv: styled.div`
      background-color: white;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
      border-radius: 15px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 90%;
      margin: 50px 0px;
      padding: 50px 0px;
  `,

  BasicDiv: styled.div` // 세로 정렬
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      width: 80%;
      margin-bottom: 30px;
  `,

  BasicRowDiv: styled.div` // 가로 정렬 (간격 일정)
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      width: 80%;
      margin-bottom: 30px;
  `,

  RightRowDiv: styled.div` // 가로 정렬 (왼쪽)
      display: flex;
      justify-content: flex-end;
      align-items: center;
      
      width: 80%;
  `,

  TitleH4: styled.div`
      font-size: 24px;
      font-weight: bold;
      
      margin-bottom: 30px;
  `,

  InfoDiv: styled.div`
      width: 100%;
  `,

  SearchSpan: styled.span`
      color: white;
      background-color: #4A506B;

      height: 25px;
      width: 40px; /* 폭 설정 */

      display: inline-block; /* 폭과 높이를 적용 가능하게 함 */
      text-align: center; /* 텍스트 정렬 */
      line-height: 25px; /* 수직 가운데 정렬 */
      border-radius: 4px; /* 모서리를 둥글게 */
  `,

  InputSpan: styled.span`
      color: white;
      background-color: #7CCB76;

      height: 25px;
      width: 70px; /* 폭 설정 */

      display: inline-block;
      text-align: center;
      line-height: 25px;
      border-radius: 4px;
  `,

  SelectSpan: styled.span`
      color: white;
      background-color: #536dfe;

      height: 25px;
      width: 40px; /* 폭 설정 */

      display: inline-block;
      text-align: center;
      line-height: 25px;
      border-radius: 4px; 
  `,

  PageInput: styled.input`
      width: 80px;
      height: 35px;

      border: 1px solid #cccccc;
      border-radius: 5px;
      margin: 0px 10px;

      text-align: center;
      font-size: 14px;

      &:focus {
          border-color: #999999;
          outline: none;
      }
  `,

  MoveToBtn: styled.button`
      width: 50px;
      height: 35px;

      background-color: #F0F0F0;

      border: 1px solid #F0F0F0;
      border-radius: 5px;
      cursor: pointer;

      font-size: 14px;

      &:hover {
          background-color: #E0E0E0;
      }
  `,

  NaviDiv: styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;
  `,

  IconDiv: styled.div`
      //background-color: red;
      border-radius: 50%;
      
      width: 30px;
      height: 30px;
      
      margin: 0px 4px;
      
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
          background-color: #E0E0E0;
      }
  `,

  //-----------------------------------------

  SearchInput: styled.input`
      width: 570px;
      height: 35px;
      
      border: 1px solid #cccccc;
      border-radius: 5px;

      font-size: 14px;

      &:focus {
          border-color: #999999;
          outline: none;
      }
  `,

  SearchBtn: styled.button`
      width: 50px;
      height: 35px;

      background-color: #4A506B;
      color: white;

      border: 1px solid #4A506B;
      border-radius: 5px;
      cursor: pointer;
      
      font-size: 14px;

      &:hover {
          background-color: #5C617F;
      }
  `,

  InputBtn: styled.button`
      width: 80px;
      height: 35px;

      background-color: #7CCB76;
      color: white;

      border: 1px solid #7CCB76;
      border-radius: 5px;
      cursor: pointer;

      font-size: 14px;

      &:hover {
          background-color: #66BB6A;
      }
  `,

  SubmitInput: styled.input`
      width: 200px;
      height: 35px;
      
      border: 1px solid #cccccc;
      border-radius: 5px;
      margin: 0px 10px;

      font-size: 14px;

      &:focus {
          border-color: #999999;
          outline: none;
      }
  `,

  SubmitBtn: styled.button`
      width: 50px;
      height: 35px;

      background-color: #536dfe;
      color: white;

      border: 1px solid #536dfe;
      border-radius: 5px;
      cursor: pointer;
      
      font-size: 14px;

      &:hover {
          background-color: #4154d0;
      }
  `,

  //---------------------------------------

  Table: styled.table`
      width: 100%;
      border-collapse: collapse;
      margin: 20px 0;
`,

  ColGroup: styled.colgroup``,

  Col: styled.col`
      &:nth-child(1) {
        width: 10%;
      }
      &:nth-child(2) {
        width: 40%;
      }
      &:nth-child(3) {
        width: 30%;
      }
`,

  Thead: styled.thead`
      background-color: #f4f4f4;
`,

  Th: styled.th`
      text-align: center;
      padding: 10px;
      font-weight: bold;
      border-bottom: 2px solid #ddd;
`,

  Tbody: styled.tbody``,

  Td: styled.td`
      text-align: center;
      padding: 10px;
      border-bottom: 1px solid #ddd;
`,

  TbodyRow: styled.tr`
      &:nth-child(even) {
        background-color: #f9f9f9;
      }
      &:hover {
        background-color: #e9e9e9;
      }
`,

  NoDataTd: styled.td`
      text-align: center;
      padding: 20px;
      color: #999;
`,
}

export default S;
