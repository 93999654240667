import styled from 'styled-components';

const J = {
  TotalDiv: styled.div`
      width: 80%;
      
      position: relative;
      left: 10%;
      
      font-size: 0.8vw;
    
      border-radius: 0.5vw;
      
      display: flex;
      justify-content: center;
      align-items: center;
  `,

  InnerTotalDiv: styled.div`
      background-color: white;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
      border-radius: 1vw;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 50%;
      margin: 3vw 0vw;
      padding: 4vw 0vw;
  `,

  TitleH4: styled.div`
      font-size: 1.5vw;
      font-weight: bold;
  `,

  NoticeDiv: styled.div`
      width: 20vw;
      height: 2vw;

      background-color: #F3F5FF;
      color: #666666;

      border-radius: 0.3vw;
      margin: 3vw 0vw 2vw 0vw;
      padding: 0vw 0vw 0vw 1vw;

      display: flex;
      align-items: center;
  `,

  EssentialStar: styled.span`
      color: #FF6868;
      font-weight: bold;
      
      margin: 0vw 0.1vw;
  `,

  IdInputLineDiv: styled.div`
      display: flex; /* 요소들을 가로로 배치 */
      align-items: center; /* 세로 정렬 */
      gap: 1vw; /* 요소 간의 간격 설정 */
  `,

  IdInput: styled.input`
      width: 14vw;
      height: 2vw;
      
      border: 1px solid #cccccc;
      border-radius: 0.3vw;

      font-size: 0.7vw;

      &:focus {
          border-color: #536DFE;
          outline: none;
      }
  `,

  CheckBoxInput: styled.input`
      width: 1.5vw;
      height: 1.5vw;
      margin: 0vw 2vw 0vw 1.5vw;

      border: 2px solid #cccccc;
      border-radius: 50%;

      appearance: none; /* 기본 체크박스 숨김 */
      display: inline-block;
      position: relative;

      background-color: white;
      transition: all 0.2s ease;

      &:checked {
          background-color: #536dfe;
          border-color: #536dfe;
      }

      &:checked::after {
          content: '';
          position: absolute;
          top: 40%;
          left: 51.6%;
          width: 0.5vw;
          height: 0.8vw;
          border: solid white;
          border-width: 0 0.16vw 0.16vw 0;
          transform: translate(-50%, -50%) rotate(45deg);
      }

      &:hover {
          border-color: #888888;
      }
  `,

  DuplCheck: styled.button`
      width: 5vw;
      height: 2vw;

      background-color: #4A506B;
      color: white;

      border: 1px solid #4A506B;
      border-radius: 0.3vw;
      cursor: pointer;
      
      font-size: 0.8vw;

      &:hover {
          background-color: #5C617F;
      }
  `,

  CheckResultP: styled.p`
      color: #FF6868;
      font-size: 0.8vw;
      font-weight: bold;
      
      white-space: pre-line;
      
      margin: 0vw 0vw;
  `,

  WarningP: styled.p`
      font-size: 0.7vw;
      
      margin: 0vw 0vw;
  `,
  
  BasicDiv: styled.div` // 세로 정렬
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      
      width: 20vw;
      margin-bottom: 2vw;
  `,

  BasicLabel: styled.div`
      margin-bottom: 0.2vw;
      font-weight: bolder;
  `,

  Input: styled.input`
      width: 20vw;
      height: 2vw;

      border: 1px solid #cccccc;
      border-radius: 0.3vw;

      font-size: 0.7vw;

      &:focus {
          border-color: #536DFE;
          outline: none;
      }
  `,

  EmailCheckDiv: styled.div`
      width: 20vw;
      
      //background-color: red;
      border: 1px solid #cccccc;
      border-radius: 0.3vw;
      
      margin-top: 1vw;
  `,

  EmailCheckLine: styled.div`
      display: flex;
      justify-content: flex-start;
      align-items: center;
  `,

  EmailCheckInput: styled.input`
      width: 1.5vw;
      height: 1.5vw;

      border: 2px solid #cccccc;
      border-radius: 50%;

      appearance: none; /* 기본 체크박스 숨김 */
      display: inline-block;
      position: relative;

      background-color: white;
      transition: all 0.2s ease;

      &:checked {
          background-color: #536dfe;
          border-color: #536dfe;
      }

      &:checked::after {
          content: '';
          position: absolute;
          top: 40%;
          left: 51.6%;
          width: 0.5vw;
          height: 0.8vw;
          border: solid white;
          border-width: 0 0.16vw 0.16vw 0;
          transform: translate(-50%, -50%) rotate(45deg);
      }

      &:hover {
          border-color: #888888;
      }
  `,

  SelectSpan: styled.span`
      color: #888888;
      margin: 0vw 0.2vw 0vw 0.3vw;
  `,

  AboutEmailCheckP: styled.div`
      margin: 0vw 0vw;
      
      font-size: 0.7vw;
      padding: 0.5vw 0.2vw;
  `,

  SelectOrgType: styled.select`
      width: 20vw;
      height: 2vw;

      border: 1px solid #cccccc;
      border-radius: 0.3vw;

      font-size: 0.7vw;

      &:focus {
          border-color: #536DFE;
          outline: none;
      }
  `,

  InactiveInput: styled.input`
      width: 20vw;
      height: 2vw;

      background-color: #cccccc;
      border: 1px solid #cccccc;
      border-radius: 0.3vw;

      font-size: 0.8vw;

      &:focus {
          outline: none;
      }
  `,

  SubmitBtn: styled.button`
      width: 10vw;
      height: 2.8vw;

      background-color: #536DFE;
      font-size: 0.8vw;
      color: white;

      border: none; /* 테두리 제거 */
      border-radius: 0.5vw; /* 모서리 둥글게 */
      cursor: pointer; /* 버튼에 마우스 올릴 때 포인터 커서 */

      margin-top: 2vw;
      
      &:hover {
          background-color: #4154d0; /* 호버 시 색상 변경 */
      }
  `,
}

export default J;