import styled from 'styled-components';

const SM = {
  TotalDiv: styled.div`
      width: 100%;
      height: 100%;
      
      position: fixed;
      top: 0px;
      left: 0px;
      
      z-index: 2000;

      background-color: rgba(0, 0, 0, 0.2);
  `,

  InnerTotalDiv: styled.div`
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      padding: 20px;
      border-radius: 8px;
      
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  `,

  CloseDiv: styled.div`
      display: flex;
      justify-content: flex-end;
      align-items: center;
      
      width: 100%;
      
      margin-bottom: 1vw;
  `,

  CloseButton: styled.div`
      position: relative;
      width: 24px; /* X 버튼 크기 */
      height: 24px;
      cursor: pointer; /* 클릭 가능 표시 */
  `,

  Line: styled.div`
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%; /* 선 길이 */
      height: 2px; /* 선 두께 */
      background-color: black; /* 선 색상 */
      transform-origin: center;
    
      &:first-child {
        transform: translate(-50%, -50%) rotate(45deg); /* 첫 번째 선 */
      }
    
      &:last-child {
        transform: translate(-50%, -50%) rotate(-45deg); /* 두 번째 선 */
      }
  `,

  TitleH1: styled.h1`
      font-size: 1.4vw;
      
      font-weight: normal;
      margin-bottom: 0.5vw;
  `,

  DescriptionP: styled.p`
      font-size: 0.8vw;
      margin-bottom: 2vw;
  `,

  BtnDiv: styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 40%;
  `,

  ExplainP: styled.p`
      font-size: 1vw;
      margin-bottom: 0.5vw;
  `,

  OtherP: styled.p`
      margin-top: 0vw;
      margin-bottom: 2vw;
      
      font-size: 0.8vw;
  `,

  InputLabel: styled.p`
      font-size: 0.8vw;
      margin-bottom: 0.5vw;
  `,

  Input: styled.input`
      width: 15vw;
      height: 2vw;

      border: 1px solid #cccccc;
      border-radius: 0.3vw;

      font-size: 0.8vw;

      &:focus {
          border-color: #999999;
          outline: none;
      }
  `,

  WarningH6: styled.h6`
      font-size: 0.8vw;
      color: #EA6A6A;
  `,

}

export default SM;